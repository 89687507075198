<template>
  <div class="magazine-casamx">

    <b-container class="pt-5">
      <b-row>
        <b-col col md="12" class=" mb-5">
          <h1 class="display-3 super-strong">
            {{cfg.nameCategory}}
          </h1>
          <hr class="line">
        </b-col>

        <b-col col md="3" class="mb-4" v-for="magazine in magazinePages" :key="magazine.id">
          <div class="p-2">
            <router-link 
              class=""
              :to="{ 
                name: 'MagazineCasaMxPage', 
                params: { 
                  canonical: magazine.canonical,
                }
              }"
            >
              <b-img fluid :src="`${ path.storage_files + magazine.image.url }`" class="z-depth-3 p-3 box-10"></b-img >
              <h4 class="mt-3 text-uppercase">{{ magazine.title }}</h4>
            </router-link>

            <div class="mt-2">
              <b-dropdown
                text="Versiones disponibles"
                block
                variant="success"
                class=""
                menu-class="w-100"
              >
                <b-dropdown-item href="#"><i class="fas fa-link"></i> Web</b-dropdown-item>
                <b-dropdown-item href="#"><i class="fas fa-book-open"></i> Interactiva</b-dropdown-item>
                <b-dropdown-item href="#"><i class="fas fa-file-pdf"></i> PDF</b-dropdown-item>
              </b-dropdown>
            </div>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import Flipbook from 'flipbook-vue';

export default{
  name: 'MagazineCasaMx',
  components: {
    Flipbook,
  },


  props: {
    cfgTitle: String,
  },


  data() {
    return {
      magazinePages:[],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    }
  },


  mounted(){
    document.title = 'Casa de Mexico | Instituto de los Mexicanos en el Exterior';
  },


  /*
   * Ajuste segun categoria
   * Hereda de Categoria Padre
   */
  async created() {
    await fetch(
      //this.path.endpoint + '/magazines/?canonical='+ this.$route.params.canonical
      this.path.endpoint + '/magazines'
    ).then(response => response.json())
    .then(data => {
      this.magazinePages = data;
      console.log(data);
    })
    .catch(err => {
      console.error(err);
    });
  },
  /* End Ajuste */


  methods: {},


}
</script>


<style>
.flipbook {
  width: 90vw;
  height: 90vh;
  margin-bottom: 75px;
}
.flipbook .menu {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>